import useSWRStore from '../useSWRStore';

export interface ISidebarStore {
  isBucketSettingsSidebarOpen: boolean;
  isLifecycleSettingsSidebarOpen: boolean;
  isIndexSettingsSidebarOpen: boolean;
  isMergeSettingsSidebarOpen: boolean;
  isPartitionSettingsSidebarOpen: boolean;
  isStreamAuthSettingsSidebarOpen: boolean;
  isQueryOptionsSidebarOpen: boolean;
  isRateLimitSidebarOpen: boolean;
  isRenameTableSidebarOpen: boolean;
}

export const additionalSidebarFallbackData: ISidebarStore = {
  // single table page
  isBucketSettingsSidebarOpen: false,
  isLifecycleSettingsSidebarOpen: false,
  isIndexSettingsSidebarOpen: false,
  isMergeSettingsSidebarOpen: false,
  isPartitionSettingsSidebarOpen: false,
  isStreamAuthSettingsSidebarOpen: false,
  isQueryOptionsSidebarOpen: false,
  isRateLimitSidebarOpen: false,
  isRenameTableSidebarOpen: false,
};

const useToggleAdditionalSidebarStore = () =>
  useSWRStore<ISidebarStore, unknown>('toggle-additional-sidebar-store', {
    fallbackData: additionalSidebarFallbackData,
  });

export default useToggleAdditionalSidebarStore;
