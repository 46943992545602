/* eslint-disable newline-before-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Router from 'next/router';
import axios, { AxiosResponse } from 'axios';

import { getUserCookies } from 'utils/cookies';
import SKIP_PROTECTED_ROUTES from 'utils/constants/skipProtectedRoutes';
import { ROUTES } from 'utils/constants/routes';
import { IS_DEV } from 'utils/constants/common';

import { formatAdditionalAxiosParams, validateUrl } from './validateUrl';

//@ts-ignore
const flattenErrors = (value, oldData) => {
  if (Array.isArray(value) && !(typeof value[0] === 'string')) {
    const result = {};
    value.forEach((v, i) => {
      //@ts-ignore
      result[i] = flattenErrors(v, oldData && oldData[i]);
    });

    return result;
  }
  if (Array.isArray(value) && typeof value[0] === 'string') {
    // return { _error: value[0] };
    return value[0];
  }
  if (typeof value === 'string' || !value) {
    return value;
  }

  const obj = {};
  Object.keys(value).map((fieldName) => {
    //@ts-ignore
    obj[fieldName] = flattenErrors(value[fieldName], oldData && oldData[fieldName]);

    //@ts-ignore
    return obj[fieldName];
  });

  return obj;
};

const successfulResponse = async (response: AxiosResponse<any, any>) => response;

const errorResponse = async (error: {
  code?: number | string;
  config?: any;
  response: any;
  url: string | undefined;
}) => {
  let code = 0;
  let errorMessage = '';
  const { response } = error;

  if (error.code === 'ECONNABORTED') {
    code = 408;
    errorMessage = 'The request has timed out';

    return Promise.reject({
      code,
      error: {
        _error: {
          code,
          message: errorMessage,
        },
      },
    });
  }
  if (!response) {
    // request didn't make it to server
    code = 503;
    errorMessage =
      error?.code === 'InvalidUrl'
        ? `Invalid URL or URL does not match allowed patterns: ${error?.url}`
        : "API unavailable at the moment, we'll be back shortly.";
  } else if (response.status === 500) {
    code = response.status;
    errorMessage =
      'Internal server error. If this is reoccurring, ' +
      'please contact us at support@hydrolix.com';
  } else if (response.status === 501) {
    code = response.status;
    errorMessage =
      'Not Implemented. If this is reoccurring, ' + 'please contact us at support@hydrolix.com';
  } else if (response.status === 502) {
    code = response.status;
    errorMessage =
      'Bad Gateway. If this is reoccurring, ' + 'please contact us at support@hydrolix.com';
  } else if (response.status === 503) {
    code = response.status;
    errorMessage =
      'Service Unavailable. If this is reoccurring, ' + 'please contact us at support@hydrolix.com';
  } else if (response.status === 504) {
    code = response.status;
    errorMessage =
      'Gateway Timeout. If this is reoccurring, ' + 'please contact us at support@hydrolix.com';
  } else if (response.status === 401) {
    code = response.status;
    errorMessage = response.data.detail || response.statusText;
    // exceptions to prevent redirect from hitting "cancel" on basic auth dialog
    if (response.config.url.includes('/version')) {
      errorMessage = 'Version cannot be accessed';
    } else if (response.config.url.includes('/prometheus/api/v1/query_range')) {
      errorMessage = 'This panel cannot be accessed';
    } else if (Router.pathname !== ROUTES.login) {
      return Router.push(ROUTES.login + `?prevUrl=${Router.asPath}`);
    }
  } else if (response.status === 403) {
    code = response.status;
    errorMessage = response.data.detail || response.data.message;
  } else if (response.status === 404) {
    code = response.status;
    errorMessage = `${response.status} Error: ${response.statusText}.`;
  } else if (response.status === 406) {
    code = response.status;
    errorMessage = response.statusText;
  }

  if (response?.status === 400 && response.config.url.includes('?query=')) {
    code = response.status;
    errorMessage = response.data || response.statusText;
  }

  if (code !== 0) {
    /* eslint-disable-next-line no-console */
    console.error(`request error, code: ${code}`, errorMessage);

    return Promise.reject({
      code,
      error: {
        _error: {
          code,
          message: errorMessage,
        },
      },
    });
  }
  let oldData = response.data;
  try {
    oldData = JSON.parse(error.config.data || '{}');
  } catch (err) {
    console.warn('error is', error);

    console.warn('Failed to parse request', err, error.config && error.config.data);
  }

  let finalError = {};

  const responseError = error.response && error.response.data;
  Object.keys(responseError || {}).map((fieldName) => {
    let key = fieldName;
    if (key === 'non_field_errors' || key === 'detail' || key === 'error_description') {
      key = '_error';
      //@ts-ignore
      finalError[key] = flattenErrors(responseError[fieldName], oldData[fieldName]);
    } else if (key === 'settings') {
      finalError = flattenErrors(responseError[fieldName], oldData[fieldName]);
    } else {
      //@ts-ignore
      finalError[key] = flattenErrors(responseError[fieldName], oldData[fieldName]);
    }

    //@ts-ignore
    return finalError[key];
  });

  return Promise.reject({ code, error: { ...finalError } });
};

axios.defaults.baseURL = (process.env.NEXT_PUBLIC_API_BASE_URL || '') + process.env.NEXT_PUBLIC_API;
axios.defaults.timeout = 300000;

axios.interceptors.request.use(
  (config) => {
    // token for the standard login in the dev environment. Keycloak handles this in the deployed cluster
    let token;
    if (IS_DEV) token = getUserCookies().token;
    const isSkippedPath = SKIP_PROTECTED_ROUTES.find((path) => Router.pathname.includes(path));
    try {
      let urlForCheck = config.url;

      if (config.url?.startsWith('http')) {
        const url = new URL(config.url);
        urlForCheck = url.pathname;
      }

      const additionalQueryParams = formatAdditionalAxiosParams(config.params);
      if (!!additionalQueryParams) {
        urlForCheck += additionalQueryParams;
      }

      const isUrlValid = validateUrl(urlForCheck);
      if (!isUrlValid) {
        const error = new Error('Invalid URL or URL does not match allowed patterns');
        (error as any).code = 'InvalidUrl';
        (error as any).url = urlForCheck;
        return Promise.reject(error);
      }
    } catch (err) {
      console.error(err);
    }

    if (config?.headers && !isSkippedPath) {
      // Include authentication cookies if they are present. The token no longer needs to be added to the Authorization
      // header, as the cookies contain authorization information.
      if (IS_DEV && !!token) config.headers.Authorization = `Bearer ${token}`;
      config.withCredentials = true;
      config.headers['X-Frame-Options'] = IS_DEV ? null : 'DENY';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(successfulResponse, errorResponse);
