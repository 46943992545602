import { createGlobalStyle } from 'styled-components';

import { inter } from 'pages/_app';
const GlobalStyle = createGlobalStyle`

:root {
  --font-inter: ${inter.style.fontFamily};
}

* {
  font-family: var(--font-inter);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  position: relative;
}

button {
  border: none;
  background-color: transparent;  
  padding: 0;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

form {
  width: 100%;
}

textarea, input {
    font-family: var(--font-inter);
    outline: none;
}
::-webkit-input-placeholder {
    font-family: var(--font-inter);
}
:-moz-placeholder {
  font-family: var(--font-inter);
}
::-moz-placeholder {
  font-family: var(--font-inter);
}
:-ms-input-placeholder {
  font-family: var(--font-inter);
}

.monaco-editor .margin  {
  width: 36px !important;
  background: #f7f7f7 !important;
}

.monaco-editor .margin .margin-view-overlays  {
  width: 36px !important;  
}

.monaco-editor .margin .line-numbers {  
  width: 36px !important;  
  font-size: 11px !important;
  text-align: center !important;
}

.monaco-editor .current-line {  
  border: none !important;
}

.monaco-editor .monaco-scrollable-element.editor-scrollable.vs.mac {  
  left: 55px !important
}

.not-overflowed-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.top-tooltip {
  max-width: 800px;
  padding: 5px 20px !important;
  margin: 0 !important;
  white-space: normal !important;

  &.smallMode {
    max-width: 450px;
  }
}

.monaco-editor .suggest-widget {
  max-width: 300px !important;
  left: 50px !important;
}

.__react_component_tooltip.type-dark .table{
  color: white !important;
}

.image-color-for-components-kit {
  stroke: black;
}

.transform-output-column-tabs {
  width: 50%;
}

.transform-output-column-tabs {
  width: 50%;
}

.hover-container {
  cursor: pointer;
  color: inherit;
}

.hover-container:hover {
  color: #4555E9;
}

`;

export default GlobalStyle;
